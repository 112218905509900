/* global.css */
body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    background: #DFE3E8;
    border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
    background: #637381;
    border-radius: 10px;
    border: '2px solid #F9FAFB';
}

body::-webkit-scrollbar-thumb:hover {
    background: #454F5B
}

/* Add these rules for non-WebKit browsers if needed */
/* body {
    scrollbar-width: thin;
    scrollbar-color: #637381 #F9FAFB;
} */