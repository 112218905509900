@keyframes circle-pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(242, 242, 242, 1);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(180, 198, 16, 0);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(205, 226, 19, 0);
    }
  }

  @keyframes gradient  {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
  }
